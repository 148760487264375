import { useState, useEffect } from "react"
import { useParams, useSearchParams } from "react-router-dom";

//Survey imports
//import 'survey-core/modern.css';
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model, surveyLocalization } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { swedishSurveyStrings } from "./localizations/sv";
import { finnishSurveyStrings } from "./localizations/fi";
import { norwegianSurveyStrings } from "./localizations/no";
import * as SurveyCore from "survey-core";

import QRCode from "react-qr-code";

//firestore db
import db from './helpers/firestore';
import { setDoc, addDoc, collection, doc, getDoc } from 'firebase/firestore';

import $ from "jquery";

import "jquery-bar-rating/dist/themes/css-stars.css";
import "jquery-bar-rating";
import * as widgets from "surveyjs-widgets";

import './custom.css'


window["$"] = window["jQuery"] = $;

widgets.jquerybarrating(SurveyCore, $);

export default function SurveyRoute() {

    surveyLocalization.locales["no"] = norwegianSurveyStrings;
    surveyLocalization.localeNames["no"] = "norsk";
    surveyLocalization.locales["fi"] = finnishSurveyStrings;
    surveyLocalization.localeNames["fi"] = "suomi";
    surveyLocalization.locales["sv"] = swedishSurveyStrings;
    surveyLocalization.localeNames["sv"] = "svenska";

    StylesManager.applyTheme("defaultV2");

    const {surveyId, type, lang, hash } = useParams();
    
    const [searchParams] = useSearchParams();

    const [ isLoading, setIsLoading ] = useState(true);

    const [surveyModel, setSurveyModel ] = useState();

    const [ isCompleted, setIsCompleted] = useState();

    const [responseId, setResponseId] = useState('');

    const complete = ((sender) => {
        save(sender);
        console.log('Complete: ', responseId);
        setIsCompleted(true);
    })

    const save = ((sender) => {
        setIsLoading(true)
        console.log('Save: ', responseId);
        if (responseId){
            const docRef = doc(db, "responses", responseId);
            setDoc(docRef, sender.data, { merge: true}).then(() =>{
                setIsLoading(false)
                return;
            });
        }
        else{

            addDoc(collection(db, "responses"), sender.data)
            .then((docRef) => {
                setIsLoading(false)

                setResponseId(docRef.id)
                setIsLoading(false);
                return
            })
          }
    })


    


    useEffect(() => {

        const loadSurvey = async(hash, type) =>{

            const docref = doc(db, `surveys`, surveyId);        
            const docSnap = await getDoc(docref);
    
            let survey = new Model(docSnap.data());
            survey.sendResultOnPageNext = true;
            //survey.onComplete.add(complete);
            //survey.onPartialSend.add(save);
            
            survey.locale = lang;
            
            survey.data = {
              languageId : lang,
              surveyId : surveyId
            };
    
            if (hash){
    
              survey.clientId = hash;
              //Convert hash to piped string
              // 2022-12-01;Bad Gastein;Salzburger Hof;xx@alpresor.se;10002323
    
    
              let base64ToString = atob(hash);
    
              const userinfo = base64ToString.split(';');
              const travelDate = userinfo[0];
              const destination = userinfo.length > 1 ? userinfo[1] : '';
              const hotel = userinfo.length > 2 ? userinfo[2] : '';
              const email = userinfo.length > 3 ? userinfo[3] : '';
              const bookingId = userinfo.length > 4 ? userinfo[4] : '';
              const destination_country = userinfo.length > 5 ? userinfo[5] : '';
              const showCustomerDataPage = userinfo.length > 5 ? true : false;
    
              var surveyUrl = window.location.href.split('?')[0].replace(hash, '');
    
              while (surveyUrl.endsWith('/')){
                surveyUrl = surveyUrl.slice(0,-1);
              }
              const shareLink = surveyUrl + '/' +  btoa(`${travelDate};${destination};${hotel};;;${destination_country}`);
    
    
              survey.data = {
                travel_date: travelDate,
                destination : destination,
                destination_country : destination_country,
                hotel : hotel,
                email : email,
                bookingId: bookingId,
                customerData_show : (travelDate && destination && hotel && email) && showCustomerDataPage,
                shareLink : shareLink,
                surveyId: surveyId,
                languageId: lang            
              }
    
              if (survey.data.customerData_show){
                survey.currentPageNo = 1;              
              }
              if (type === 'conference'){
                survey.currentPageNo = 0;
              }
            }
    
            const nps = searchParams.get('nps');
            if (nps){
              survey.data = {
                nps_score : nps,
                ...survey.data
              };
            }
    
            console.log(survey.data)
            return setSurveyModel(survey);
    
        }

        loadSurvey(hash, type).then(() => {
            setIsLoading(false);
          })     
    },[hash,type, lang, searchParams, surveyId])


    return (
        <div>
            <header style={{ maxWidth: '100%', textAlign:'left', padding:'20px 5%', display:'flex', justifyContent:'space-between', background:'#ffffff'}}>
               { lang === 'sv' && <img src="https://www.alpresor.se/globalassets/global-images/alpresor-loggor/alpresor_se_payoff_vinter.svg" style={{ maxWidth:'200px' }} alt="logo"/>}
               { lang === 'fi' && <img src="https://www.alpresor.se/globalassets/alppimatkat_logo.svg" style={{ maxWidth:'200px' }} alt="logo"/>}
               { lang === 'no' && <img src="https://www.alpresor.se/globalassets/global-images/alpresor-loggor/alpereiser_logo.svg" style={{ maxWidth:'200px' }} alt="logo"/>}
               { isLoading && <img src='/loader.svg'  height={35} alt='loader' />}

            </header>           
            { surveyModel && <Survey className={`surveyElement ${ isCompleted ? 'completed' :'' } lang-${ lang }`} model={surveyModel} key="survey" onPartialSend={(sender) => save(sender)} onComplete={(sender) => complete(sender)} /> }

            { isLoading && !surveyModel && 
              <div className="sd-root-modern">
                <div className="loader">
                  <div>
                    <img src='/loader.svg' width={50} height={50} alt='loader' />
                  </div>
                </div>
              </div>
            }
            { type !== 'survey' && surveyId !== 'conference' && isCompleted && surveyModel &&
              <div style={{ maxWidth:'100%', textAlign:'center', padding:'20px' }}>
                <QRCode value={surveyModel.data.shareLink}  />
              </div>
            }
        </div>
    );
}
